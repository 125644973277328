const isEmpty = val => val === null || val === undefined;

export default ((trigger, event, ...args) => {
  const doTrigger = (event, ...args) => new Promise((resolve, reject) => {
    const results = trigger(event, ...args);

    if (results && results.length) {
      Promise.all(results).catch(reject).then(resolvedResults => {
        let result;

        if (resolvedResults) {
          while (isEmpty(result) && resolvedResults.length) {
            result = resolvedResults.pop();
          }
        }

        resolve(isEmpty(result) ? undefined : result);
      });
    } else {
      resolve();
    }
  });

  return event ? doTrigger(event, ...args) : doTrigger;
});