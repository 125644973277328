import { FILE_STATES, logger } from "@rpldy/shared";
import processBatchItems from "./processBatchItems";
import { getBatchDataFromItemId, getIsItemBatchReady, isNewBatchStarting, cancelBatchForItem, loadNewBatchForItem, isItemBelongsToBatch } from "./batchHelpers";

const getIsItemInActiveRequest = (queue, itemId) => {
  return !!~queue.getState().activeIds // $FlowFixMe - no flat
  .flat().indexOf(itemId);
};

const getIsItemReady = item => item.state === FILE_STATES.ADDED;

export const findNextItemIndex = queue => {
  const state = queue.getState(),
        itemQueue = state.itemQueue,
        items = state.items;
  let index = 0,
      nextId = itemQueue[index]; //find item that isnt already in an active request and belongs to a "ready" batch

  while (nextId && (getIsItemInActiveRequest(queue, nextId) || !getIsItemBatchReady(queue, nextId) || !getIsItemReady(items[nextId]))) {
    index += 1;
    nextId = itemQueue[index];
  }

  return nextId ? index : -1;
};
export const getNextIdGroup = queue => {
  const itemQueue = queue.getState().itemQueue;
  const nextItemIndex = findNextItemIndex(queue);
  let nextId = itemQueue[nextItemIndex],
      nextGroup;

  if (nextId) {
    const batchData = getBatchDataFromItemId(queue, nextId);
    const batchId = batchData.batch.id,
          groupMax = batchData.batchOptions.maxGroupSize || 0;

    if (batchData.batchOptions.grouped && groupMax > 1) {
      nextGroup = [];
      let nextBelongsToSameBatch = true; //dont group files from different batches

      while (nextGroup.length < groupMax && nextBelongsToSameBatch) {
        nextGroup.push(nextId);
        nextId = itemQueue[nextItemIndex + nextGroup.length];
        nextBelongsToSameBatch = nextId && isItemBelongsToBatch(queue, nextId, batchId);
      }
    } else {
      nextGroup = [nextId];
    }
  }

  return nextGroup;
};

const processNext = queue => {
  const ids = getNextIdGroup(queue);
  let resultP = Promise.resolve();

  if (ids) {
    const currentCount = queue.getCurrentActiveCount(),
          {
      concurrent = 0,
      maxConcurrent = 0
    } = queue.getOptions();

    if (!currentCount || concurrent && currentCount < maxConcurrent) {
      logger.debugLog("uploader.processor: Processing next upload - ", {
        ids,
        state: queue.getState(),
        currentCount
      });
      let cancelled = false;
      let newBatchP = Promise.resolve(false);

      if (isNewBatchStarting(queue, ids[0])) {
        newBatchP = loadNewBatchForItem(queue, ids[0]).then(allowBatch => {
          cancelled = !allowBatch;

          if (cancelled) {
            cancelBatchForItem(queue, ids[0]);
            processNext(queue);
          }

          return cancelled;
        });
      }

      resultP = newBatchP.then(cancelled => {
        if (!cancelled) {
          processBatchItems(queue, ids, processNext);
        }
      });
    }
  }

  return resultP;
};

export default processNext;