import createUploadQueue from "./queue";
import createItemsSender from "./batchItemsSender";
import createBatch from "./batch";
export default ((trigger, cancellable, options, uploaderId) => {
  const sender = createItemsSender(),
        queue = createUploadQueue(options, trigger, cancellable, sender, uploaderId);
  const runCancellable = queue.runCancellable;
  return {
    process: (batch, batchOptions) => {
      queue.uploadBatch(batch, batchOptions);
    },
    abortBatch: batchId => {
      queue.abortBatch(batchId);
    },
    abort: id => {
      if (id) {
        queue.abortItem(id);
      } else {
        queue.abortAll();
      }
    },
    addNewBatch: (files, uploaderId, processOptions) => {
      const batch = createBatch(files, uploaderId, processOptions);
      return queue.addBatch(batch, processOptions);
    },
    runCancellable,
    clearPendingBatches: () => {
      queue.clearPendingBatches();
    },
    processPendingBatches: uploadOptions => {
      queue.uploadPendingBatches(uploadOptions);
    }
  };
});