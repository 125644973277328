const triggerCancellable = (trigger, event, ...args) => {
  const doTrigger = (event, ...args) => new Promise((resolve, reject) => {
    const results = trigger(event, ...args);

    if (results && results.length) {
      Promise.all(results).catch(reject).then(resolvedResults => resolvedResults && resolve(!!~resolvedResults.findIndex(r => r === false)));
    } else {
      resolve(false);
    }
  });

  return event ? doTrigger(event, ...args) : doTrigger;
};

export default triggerCancellable;