import { useEffect, useState, useCallback } from "react";
import { isProduction } from "@rpldy/shared";
import { UPLOAD_OPTIONS_COMP } from "./consts";
import useUploadyContext from "./useUploadyContext";

const useEventEffect = (event, fn) => {
  const context = useUploadyContext();
  const {
    on,
    off
  } = context;
  useEffect(() => {
    on(event, fn);
    return () => {
      off(event, fn);
    };
  }, [event, fn, on, off]);
};

const generateUploaderEventHookWithState = (event, stateCalculator) => (fn, id) => {
  const [eventState, setEventState] = useState(null);
  const eventCallback = useCallback((eventObj, ...args) => {
    if (!id || eventObj.id === id) {
      setEventState(stateCalculator(eventObj, ...args));

      if (fn) {
        fn(eventObj, ...args);
      }
    }
  }, [fn, id]);
  useEventEffect(event, eventCallback);
  return eventState;
};

const generateUploaderEventHook = (event, canScope = true) => (fn, id) => {
  const eventCallback = useCallback((eventObj, ...args) => {
    return fn && (!canScope || !id || eventObj.id === id) ? fn(eventObj, ...args) : undefined;
  }, [fn, id]);
  useEventEffect(event, eventCallback);
};

const logWarning = (condition, msg) => {
  if (!isProduction() && !condition) {
    // eslint-disable-next-line no-console
    console.warn(msg);
  }
};

const markAsUploadOptionsComponent = Component => {
  Component[UPLOAD_OPTIONS_COMP] = true;
};

const getIsUploadOptionsComponent = Component => {
  var _Component$target, _Component$render;

  return Component[UPLOAD_OPTIONS_COMP] === true || ((_Component$target = Component.target) === null || _Component$target === void 0 ? void 0 : _Component$target[UPLOAD_OPTIONS_COMP]) === true || ((_Component$render = Component.render) === null || _Component$render === void 0 ? void 0 : _Component$render[UPLOAD_OPTIONS_COMP]) === true;
};

export { generateUploaderEventHook, generateUploaderEventHookWithState, logWarning, markAsUploadOptionsComponent, getIsUploadOptionsComponent };